<template lang="pug">
  .wrapper
    .container(v-if="loading")
      Spinner
    .container(v-if="!loading")
      .loginContainer(v-bind:class="{active:isActive}")
        h1 登入臭豆腐
        p.pointer(v-show="!register" @click="register = true") 註冊 
        p.pointer(v-show="register" @click="register = false") 登入
        .loginRow.fbRow
          a.fbLogin(href="https://stinkytofu.tw/api/auth/facebook") 
            span 使用 Facebook 登入
        .loginRow
          label(for="email") 電子信箱
          input#email(v-on:focus="isActive = true" v-on:blur="isActive=false" type="text" v-model.trim="email")
        transition(name="fade")
          .loginRow(v-if="register")
            label(for="name") 顯示名稱
            input#name(v-on:focus="isActive = true" v-on:blur="isActive=false" type="text" v-model.trim="displayName")
        .loginRow
          label(for="password") 密碼
          input#password(v-on:keyup.enter="login" v-on:focus="isActive = true" v-on:blur="isActive=false" type="password" v-model.trim="password")
        
        button.pointer(v-on:click="login") Submit

</template>

<script>
import Spinner from 'vue-simple-spinner'

export default {
  name: 'Login',
  components: {
    Spinner,
  },
  data(){
    return {
      email:null,
      password:null,
      loading:false,
      isActive:false,
      register:false,
      displayName:null,
    }
  },
  metaInfo() {
    
    return {
      title: '登入',
      titleTemplate: "%s - 臭豆腐 - 電影∣影集∣評分∣評論"
    }    
  },
  methods:{
    async login(){
      var data = {email:this.email,password:this.password, displayName:this.displayName}
      var response;
      if(!this.register) response = await this.axios.post('/api/login/local', data)
      if(this.register) response = await this.axios.post('/api/register/local', data)
      if(response.data.error) this.flash(response.data.message, 'error')
      if(response.data.success == true){
        this.$store.dispatch('loginCheck')
        if (sessionStorage.getItem('loginClick')) {
          this.$router.go(-1)
        }
        else this.$router.push({path:'/'})
        sessionStorage.removeItem('loginClick');
      }
    },
    async fbLogin(){
      var response = await this.axios.get('/api/auth/facebook',{withCredentials: true})

    },
  }
}
</script>
<style lang="scss" scoped>
.loginContainer{
  max-width:600px;
  margin:100px auto;
  background-color:#fafafa;
  border:solid 1px lightgrey;
  padding:20px;
  border-radius:4px;
  box-shadow: 0px 4px 10px -2px grey;
  transition: box-shadow .5s;
  h1{
    text-align: center;
  }
  p{
    text-align:right;
  }
  label{
    margin:10px;
  }
  input{
    margin:10px;
    width:100%;
  }
  button{
    text-align:right;
  }
}
.loginContainer.active{
  box-shadow: 0px 4px 4px -4px grey;

}
.loginRow{
  margin:10px 0px;
}
.fbLogin{
  width:75%;
  margin:0 auto;
  color:white;
  padding: 8px 32px;
  background-color: #3b5998;
  border-radius: 2px;
  box-shadow: 0 4px 6.4px -2.4px rgba(5,5,5,0.3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.fbRow:after{
  content:"";
  display:block;
  width:50%;
  margin:20px auto;
  border:solid 1px lightgrey;
}
</style>
